import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const InfoWeb = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "en-US",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | blog/news websites"
        description="The blog or news websites is ideal when you have contents that you want to create, update or delete by yourself."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>blog / news websites</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                The blog or news website is ideal when you have contents that
                you want to create, update or delete by yourself.
                <br />
                <br />
                The blog or news website also has all the features of the
                informative website meaning that you also have a few custom
                pages to publish other contents.
                <br />
                <br />
                In this case we will prepare an interface where you can manage
                your contents. These contents will be kept in a database and
                when the website is rebuilt (just a regular operation) the
                system access the database and uses that information to create
                every page.
                <br />
                <br />
                See a sample:
                <br />
                <br />
              </span>
              <div>
                <a
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring px-3 py-1.5"
                  href="https://troncalradio.com/blogs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  troncalradio.com
                </a>
              </div>
              <div className="h-8" />
              <div className="text-center">
                <span className="text-2xl">
                  Ready to work together?
                  <br />
                  <br />
                </span>
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/en/lets-work-together/?service=blog-news-website"
                >
                  let's start a blog / news website
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default InfoWeb
